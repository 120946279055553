<template>
    <div>
        <sui-form-fields :fields="2">
            <sui-form-field required :error="user.errorFirstName">
                <label>First Name</label>
                <input type="text" name="user-first-name" placeholder="First Name" v-model="user.firstName">
            </sui-form-field>
            <sui-form-field required :error="user.errorLastName">
                <label>Last Name</label>
                <input type="text" name="user-last-name" placeholder="Last Name" v-model="user.lastName">
            </sui-form-field>
        </sui-form-fields>
        <sui-form-field required :error="user.errorEmail">
            <label>Email</label>
            <input type="email" name="user-email" placeholder="Email" v-model="user.email">
        </sui-form-field>
        <sui-form-field>
            <label>Status</label>
            <sui-dropdown
                    selection
                    :options="statusOptions"
                    v-model="user.status"
            />
        </sui-form-field>
        <sui-form-field>
            <label>Associated Roles</label>
            <sui-dropdown
                    placeholder="Associated Roles"
                    selection
                    :options="associatedRolesOptions"
                    v-model="user.associatedRoles"
            />
        </sui-form-field>
        <sui-form-field>
            <label>Contact Number</label>
            <input type="text" name="user-contact-number" placeholder="+1 XXX-XXX-XXXX" v-model="user.contactNumber">
        </sui-form-field>
        <sui-form-field>
            <label>Alternate Contact Number</label>
            <input type="text" name="user-alternate-contact-number" placeholder="+1 XXX-XXX-XXXX"
                   v-model="user.alternateContactNumber">
        </sui-form-field>
        <sui-divider></sui-divider>

        <sui-form-field>
            <label>Company</label>
            <input type="text" name="user-company" placeholder="Company" v-model="user.company">
        </sui-form-field>
        <sui-form-field>
            <label>Job Title</label>
            <input type="text" name="user-alternate-contact-number" placeholder="Job Title" v-model="user.jobTitle">
        </sui-form-field>
        <sui-divider></sui-divider>

        <sui-form-field>
            <label>Street</label>
            <input type="text" name="user-street" placeholder="Street" v-model="user.street">
        </sui-form-field>
        <sui-form-field>
            <label>City</label>
            <input type="text" name="user-street" placeholder="City" v-model="user.city">
        </sui-form-field>
        <sui-form-field>
            <label>Zip Code</label>
            <input type="text" name="user-street" placeholder="Zip Code" v-model="user.zipCode">
        </sui-form-field>
        <sui-form-field>
            <label>Country</label>
            <input type="text" name="user-street" placeholder="Country" v-model="user.country">
        </sui-form-field>
        <sui-form-field>
            <label>State</label>
            <input type="text" name="user-street" placeholder="State" v-model="user.state">
        </sui-form-field>
    </div>
</template>

<script>

    export default {
        name: "AccessForm",
        components: {},
        props: {
            user: {
                firstName: String,
                lastName: String,
                email: String,
                contactNumber: String,
                alternateContactNumber: String,
                company: String,
                jobTitle: String,
                street: String,
                city: String,
                zipCode: String,
                country: String,
                state: String,
                status: String,
                associatedRoles: Array,
                loading: Boolean,

                errorFirstName: Boolean,
                errorLastName: Boolean,
                errorEmail: Boolean,
            }
        },
        data: function () {
            let status_list = ["Active", "Suspended", "Inactive"];

            return {
                statusOptions: status_list.map((i) => {
                    return {
                        text: i,
                        value: i
                    }
                }),
                associatedRolesOptions: []
        }
        },
        created: async function () {
            await this.getUserRole();
        },
        methods: {
            getUserRole: async function () {
                let roles_list = [];
                const role = await this.$config.userRole();
                if (role === "AS") {
                    roles_list = [
                        'Administrative Staff',
                        'Property Manager',
                        'Building Manager',
                        'Security Guard',
                        'Security Installer'
                    ];
                } else if (role === "PM") {
                    roles_list = [
                        'Building Manager',
                        'Security Guard',
                        'Security Installer'
                    ];
                }

                this.associatedRolesOptions = roles_list.map((i) => {
                    return {
                        text: i,
                        value: i.split(" ").reduce((a, v) => {
                            return a + v[0]
                        }, "")
                    }
                });
            }
        }

    }
</script>

<style scoped>

</style>